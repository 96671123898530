import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

/* initial State Object */
const initialStateObject = {
  loading: true,
  accountList: [],
  error: null,
};

/* Get Accounts List API Url */
const getAccountApiUrl = `${process.env.REACT_APP_API_BASE_URL}account-management/users/`;

const getActivitiesApiUrl = `${process.env.REACT_APP_API_BASE_URL}activity-management/users/`;

/* Get Accounts List */
export const getAccounts = createAsyncThunk('accounts/fetchProductList', (payload) =>
  axios
    .get(
      `${getAccountApiUrl + payload.userId}/applications/accounts?limit=${payload.rowsPerPage}&page=${
        payload.page
      }&sort_by=${payload.orderBasedOn}:${payload.order}&search=${payload.searchElement}`
    )
    .then((response) => response.data)
);

export const getActivities = createAsyncThunk('accounts/fetchProductList', (payload) =>
  axios
    .get(
      `${getActivitiesApiUrl + payload.userId}/activities?limit=${payload.rowsPerPage}&page=${payload.page}&sort_by=${
        payload.orderBasedOn
      }:${payload.order}&search=${payload.searchElement}`
    )
    .then((response) => response.data)
);

const accountSlice = createSlice({
  name: 'accounts',
  initialState: initialStateObject,
  extraReducers: (builder) => {
    builder.addCase(getAccounts.pending, (state) => {
      state.loading = true;
      state.accountList = [];
      state.error = null;
    });
    builder.addCase(getAccounts.fulfilled, (state, action) => {
      state.loading = false;
      state.accountList = action.payload;
      state.error = null;
    });
    builder.addCase(getAccounts.rejected, (state, action) => {
      state.loading = false;
      state.accountList = [];
      state.error = action.error.message;
    });
  },
});

export default accountSlice.reducer;
