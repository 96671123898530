import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

/* initial State Object */
const initialStateObject = {
  loading: true,
  notificationList: [],
  error: null,
};

/* Get Accounts List API Url */
const getNotificationApiUrl = `${process.env.REACT_APP_API_BASE_URL}notification-management/users/`;
/* Get Accounts List */
export const getNotifications = createAsyncThunk('notification/getNotifications', (payload) =>
  axios
    .get(`${getNotificationApiUrl}${payload.userId}/notifications?limit=${payload.rowsPerPage}&page=${payload.page}`)
    .then((response) => response.data)
);

export const postNotification = createAsyncThunk('notification/postNotification', (payload) => {
  return axios.post(`${getNotificationApiUrl}${payload}/notifications/read-all`).then((response) => response.data);
});

export const postSingleNotification = createAsyncThunk('notification/postSingleNotification', (payload) => {
  return axios
    .post(`${getNotificationApiUrl}${payload.userId}/notifications/${payload.notificationId}/read`)
    .then((response) => response.data);
});

const notificationSlice = createSlice({
  name: 'notification',
  initialState: initialStateObject,
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state) => {
      state.loading = true;
      state.notificationList = [];
      state.error = null;
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.loading = false;
      state.notificationList = action.payload;
      state.error = null;
    });
    builder.addCase(getNotifications.rejected, (state, action) => {
      state.loading = false;
      state.notificationList = [];
      state.error = action.error.message;
    });
  },
});

export default notificationSlice.reducer;
