import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import productsReducer from '../store/products.slice';
import applicationReviewReducer from '../store/application_review';
import profileReducer from '../store/profile.slice';
import productSelectionReducer from '../store/product-selection.slice';
import accountReducer from '../store/accounts.slice';
import signInReducer from '../store/sign-in.slice';
import updateProfileReducer from '../store/update-profile.slice';
import fileuploadReducer from '../store/fileupload.slice';
import notificationReducer from '../store/notification.slice';


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['profile'],
};

const rootReducer = combineReducers({
  products: productsReducer,
  applicationReview: applicationReviewReducer,
  profile: profileReducer,
  selectedProduct: productSelectionReducer,
  accounts: accountReducer,
  signIn: signInReducer,
  updateProfile: updateProfileReducer,
  fileUpload: fileuploadReducer,
  notifications:notificationReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
export default store;
