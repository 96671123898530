import PropTypes from 'prop-types';
import { Avatar, Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import itemStyles from './product-card.css';
import { selectProduct } from '../../store/product-selection.slice';
import placeholder from '../../assets/property-placeholder.webp';

export function ProductCard({ property, product, ...rest }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleProductSelection = () => {
    dispatch(selectProduct(product));
    navigate('/dashboard/investments');
  };
  ProductCard.propTypes = {
    product: PropTypes.object.isRequired,
  };

  return (
    <>
      {' '}
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
        {...rest}
      >
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pb: 3,
            }}
          >
            {/* <Avatar alt="Product" src={product.image_url} variant="square" /> */}
            {property ? (
              // <img alt="Product" src={product.image_urls[0].url} />
              <Box
                sx={{
                  height: '15rem',
                  width: '100%',
                  backgroundImage: `url(${product.image_urls[0].url})`,
                  backgroundSize: 'cover',
                }}
              />
            ) : (
              <Box
                sx={{
                  height: '15rem',
                  width: '100%',
                  backgroundImage: `url(${product.image_url}) , url(${placeholder})`,
                  backgroundSize: 'cover',
                }}
              />
            )}
          </Box>
          <Typography align="center" color="textPrimary" gutterBottom variant="h5">
            {product.name || product.title}
          </Typography>
          {product?.maturity_period && (
            <Typography align="center" color="textPrimary" gutterBottom variant="body1">
              {'Matures after '}
              {product?.maturity_period} {' years'}
            </Typography>
          )}
          {product?.rate_of_dividend && (
            <Typography align="center" color="textPrimary" gutterBottom variant="body1">
              {'The fixed rate of dividend is '}
              {product?.rate_of_dividend} {'%'}
            </Typography>
          )}

          <Typography sx={{ display: 'flex' }} align="center" color="textPrimary" variant="body1">
            {property ? (
              product.subtitle
            ) : (
              <div dangerouslySetInnerHTML={{ __html: product.description }} className={'itemStyles'} />
            )}
          </Typography>
        </CardContent>
        <Box sx={{ flexGrow: 1 }} />
        <Divider />
        <Box
          sx={{ p: 2 }}
          className="bg-gradient-to-r from-green-200 to-green-700 hover:cursor-pointer"
          onClick={() => !property && handleProductSelection()}
        >
          <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
            <Grid
              item
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              {/* <DownloadIcon color="action" /> */}
              <Typography color="white" display="inline" sx={{ pl: 1 }} variant="body2">
                {property ? 'View' : 'Invest Now'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
}
