/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment,
} from '@mui/material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';

import { getActivities } from '../store/accounts.slice';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'application_id', label: 'Application Id', alignRight: false },
  { id: 'product_name', label: 'Product Name', alignRight: false },
  { id: 'no_of_shares', label: 'Number of Shares', alignRight: false },
  { id: 'payment_amount', label: 'Face Value', alignRight: false },
  { id: 'date_of_purchase', label: 'Applied Date', alignRight: false },
  { id: 'time_of_purchase', label: 'Applied Time', alignRight: false },
  { id: 'date_payment_received', label: 'Payment Received Date', alignRight: false },
  { id: 'allotment_date', label: 'Allotment Date', alignRight: false },
  { id: 'application_status', label: 'Status', alignRight: false },
];

export default function ActivityPage() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('date_of_purchase');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [searchElement, setsearchElement] = useState('');

  const [debouncedsearchElement] = useDebounce(searchElement, 1000);

  const dispatch = useDispatch();

  /* Get the State of the Product slice */
  const profileState = useSelector((state) => state.profile);
  const { profile } = profileState;

  const payload = {
    userId: profile.user_id,
    rowsPerPage,
    page,
    order,
    orderBasedOn: 'date_of_purchase',
    searchElement,
  };
  /* Accounts API GET Call */
  useEffect(() => {
    dispatch(getActivities(payload));
  }, [rowsPerPage, page, order, debouncedsearchElement]);

  /* Get the State of the Accounts slice */
  const accountState = useSelector((state) => state.accounts);
  const { accountList } = accountState;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'desc';
    setOrder(isAsc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = accountList?.items?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const filteredUsers = accountList?.items; // applySortFilter(accountList?.items, getComparator(order, orderBy), filterName);

  return (
    <>
      <Helmet>
        <title> Investments </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Activities
          </Typography>
        </Stack>

        <Card>
          <TextField
            className="m-6 w-1/2"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              ),
            }}
            placeholder="Search Product name or Status..."
            onChange={(event) => setsearchElement(event.target.value)}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={accountList?.items?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers?.map((row) => {
                    const {
                      id,
                      product_name,
                      share_price,
                      application_status,
                      no_of_shares,
                      date_of_purchase,
                      date_payment_received,
                      allotment_date,
                      application_id,
                    } = row;
                    const selectedUser = selected.indexOf(product_name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell component="th" scope="row" padding="normal">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {application_id}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{product_name}</TableCell>
                        <TableCell align="left">{no_of_shares}</TableCell>

                        <TableCell align="left">{share_price}</TableCell>

                        <TableCell align="left">{moment(new Date(date_of_purchase)).format('DD-MM-yyyy')}</TableCell>
                        <TableCell align="left">{moment(new Date(date_of_purchase)).format('hh:mm A')}</TableCell>
                        <TableCell align="left">
                          {moment(new Date(date_payment_received)).format('DD-MM-yyyy')}
                        </TableCell>
                        {application_status !== 'rejected' ? (
                          <TableCell align="left">{moment(new Date(allotment_date)).format('DD-MM-yyyy')}</TableCell>
                        ) : (
                          <TableCell align="left">{''}</TableCell>
                        )}
                        <TableCell align="left">
                          <Label
                            color={
                              (application_status === 'REJECTED' && 'error') ||
                              (application_status === 'PENDING' && 'warning') ||
                              'success'
                            }
                          >
                            {sentenceCase(application_status)}
                          </Label>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {accountList?.items?.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No results found
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={accountList?.meta?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
