import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

/* initial State Object */
const initialStateObject = {
  loading: true,
  productList: [],
  propertyList: [],
  propertyInterest: {},
  error: null,
};

/* Get Products List API Url */
const getProductListApiUrl = `${process.env.REACT_APP_API_BASE_URL}product-management/products`;
const getPropertyListApiUrl = `${process.env.REACT_APP_API_BASE_URL}properties`;
const propertyInterestApiUrl = `${process.env.REACT_APP_API_BASE_URL}properties/`;

/* Get Products List */
export const getProducts = createAsyncThunk('products/fetchProductList', () =>
  axios.get(getProductListApiUrl).then((response) => response.data)
);
export const getProperties = createAsyncThunk('products/fetchPropertyList', () =>
  axios.get(getPropertyListApiUrl).then((response) => response.data)
);
export const postPropertyInterest = createAsyncThunk('products/postPropertyInterest', (payload) =>
  axios.post(`${propertyInterestApiUrl}${payload.property_id}/interests`, payload).then((response) => response.data)
);

const productSlice = createSlice({
  name: 'products',
  initialState: initialStateObject,
  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state) => {
      state.loading = true;
      state.productList = [];
      state.error = null;
    });
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.loading = false;
      state.productList = action.payload;
      state.error = null;
    });
    builder.addCase(getProducts.rejected, (state, action) => {
      state.loading = false;
      state.productList = [];
      state.error = action.error.message;
    });
    // Properties
    builder.addCase(getProperties.pending, (state) => {
      state.loading = true;
      state.propertyList = [];
      state.error = null;
    });
    builder.addCase(getProperties.fulfilled, (state, action) => {
      state.loading = false;
      state.propertyList = action.payload;
      state.error = null;
    });
    builder.addCase(getProperties.rejected, (state, action) => {
      state.loading = false;
      state.propertyList = [];
      state.error = action.error.message;
    });
    // Properties propertyInterest
    builder.addCase(postPropertyInterest.pending, (state) => {
      state.loading = true;
      state.propertyInterest = {};
      state.error = null;
    });
    builder.addCase(postPropertyInterest.fulfilled, (state, action) => {
      state.loading = false;
      state.propertyInterest = action.payload;
      state.error = null;
    });
    builder.addCase(postPropertyInterest.rejected, (state, action) => {
      state.loading = false;
      state.propertyInterest = {};
      state.error = action.error.message;
    });
  },
});

export default productSlice.reducer;
