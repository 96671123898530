import { Box, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../store/products.slice';
import { InProcess } from './dashboard/inprocess';
import { Rejected } from './dashboard/rejected';
import { Approved } from './dashboard/approved';
import { TotalProfit } from './dashboard/total-profit';
import { ProductCard } from './product/product-card';

const DashboardAppPage = () => {
   const dispatch = useDispatch();

  /* Products API GET Call */
  useEffect(() => {
    dispatch(getProducts());
  }, []);

  /* Get the State of the Product slice */
  const productListState = useSelector((state) => state.products);
  const {productList} = productListState;

    /* Get the State of the Product slice */
  const profileState = useSelector((state) => state.profile);
  const {profile} = profileState;

  return (

    <>
      <title>
        Investments
      </title>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            ><Approved count={profile?.dashboard?.applications_approved } />

            </Grid>
            <Grid
              item
              xl={3}
              lg={3}
              sm={6}
              xs={12}
            >
              <InProcess count={profile?.dashboard?.applications_pending }/>
            </Grid>
            <Grid
              item
              xl={3}
              lg={3}
              sm={6}
              xs={12}
            >
              <Rejected  count={profile?.dashboard?.applications_rejected }/>
            </Grid>
            <Grid
              item
              xl={3}
              lg={3}
              sm={6}
              xs={12}
            >
              <TotalProfit sx={{ height: '100%' }} count={profile?.dashboard?.applications_total }/>
            </Grid>

          </Grid>

          <Typography
            sx={{ mb: 3, pt: 8 }}
            variant="h4"
          >
            Products
          </Typography>
          <Box sx={{ pt: 2 }}>
            <Grid
              container
              spacing={3}
            >
              {productList?.items?.length > 0 && productList?.items?.map((product) => (
                <Grid
                  item
                  key={product.product_id}
                  lg={4}
                  md={6}
                  xs={12}
                >
                  <ProductCard product={product} />
                </Grid>
              ))}
              {productListState.loading && <div className='m-auto'><CircularProgress /></div>}
            </Grid>
          </Box>

        </Container>


      </Box>
    </>
  )
}


export default DashboardAppPage;
