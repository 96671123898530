/* eslint-disable no-prototype-builtins */
import { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import * as yup from 'yup';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useDispatch, useSelector } from 'react-redux';
import { patchProfile, resetUpdateProfile } from '../../store/update-profile.slice';
import { getProfile } from '../../store/profile.slice';

const validationSchema = yup.object({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  email: yup.string().email().required('Required'),
  // phone: yup.string().required('Required'),
  state: yup.string().required('Required'),
  country: yup.string().required('Required'),
  line1: yup.string().required('Required'),
  // line2: yup.string().required('Required'),
  pin: yup.string().required('Required'),
  district: yup.string().required('Required'),
  // city: yup.string().required('Required'),
  gender: yup.string().required('Required'),
  parent: yup.string().required('Required'),
  pan: yup
    .string()
    .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "Doesn't look like a Pan card Number")
    .required('Required'),
});

export const AccountProfileDetails = (props) => {
  /* Get the State of the Product slice */
  const profileState = useSelector((state) => state.profile);
  const { profile } = profileState;

  const signInSession = useSelector((state) => state.signIn?.currentUser);

  const profileUpdate = useSelector((state) => state.updateProfile);

  const [values, setValues] = useState({
    firstName: profile?.first_name,
    lastName: profile?.last_name,
    email: signInSession?.email,
    phone: profile?.phone,
    state: profile?.addresses ? profile?.addresses[0]?.state : '',
    country: profile?.addresses ? profile?.addresses[0]?.country : '',
    line1: profile?.addresses ? profile?.addresses[0]?.address_line1 : '',
    line2: profile?.addresses ? profile?.addresses[0]?.address_line2 : '',
    pin: profile?.addresses ? profile?.addresses[0]?.pincode : '',
    district: profile?.addresses ? profile?.addresses[0]?.district : '',
    city: profile?.addresses ? profile?.addresses[0]?.city : '',
    gender: profile?.gender_type_id?.toString(),
    parent: profile?.relationship_name,
    pan: profile?.pan?.toUpperCase(),
    // account: profile?.account_no,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: profile?.first_name,
      lastName: profile?.last_name,
      email: signInSession?.email,
      phone: profile?.phone,
      dob: profile.date_of_birth,
      state: profile?.addresses ? profile?.addresses[0]?.state : '',
      country: profile?.addresses ? profile?.addresses[0]?.country : '',
      line1: profile?.addresses ? profile?.addresses[0]?.address_line1 : '',
      line2: profile?.addresses ? profile?.addresses[0]?.address_line2 : '',
      pin: profile?.addresses ? profile?.addresses[0]?.pincode : '',
      district: profile?.addresses ? profile?.addresses[0]?.district : '',
      city: profile?.addresses ? profile?.addresses[0]?.city : '',
      gender: profile?.gender_type_id?.toString(),
      parent: profile?.relationship_name,
      pan: profile?.pan?.toUpperCase(),
    },
    validationSchema,
    onSubmit: (_values) => {
      handleModifyProfile();
    },
  });

  const IsErrorMethod = (type) => formik.touched[type] && type in formik.errors;

  const dispatch = useDispatch();

  /* Products API GET Call */
  useEffect(() => {
    dispatch(resetUpdateProfile());
  }, [values.firstName]);
  /* Products API GET Call */
  useEffect(() => {
    dispatch(getProfile(signInSession?.email));
  }, []);

  const [dob, setValue] = useState(
    `${new Date().getFullYear()}-${parseInt(new Date().getMonth() + 1, 10)}-${new Date().getDate()}`
  );
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleDateChange = (newValue) => {
    setValue(
      `${new Date(newValue).getFullYear()}-${parseInt(new Date(newValue).getMonth() + 1, 10)}-${new Date(
        newValue
      ).getDate()}`
    );
  };

  // let isButtonDisabled = true;

  /* Check if the fields exists and are not empty */
  // if (
  //   values.hasOwnProperty('firstName') &&
  //   values.firstName !== null &&
  //   values.firstName !== '' &&
  //   values.hasOwnProperty('lastName') &&
  //   values.lastName !== null &&
  //   values.lastName !== '' &&
  //   values.hasOwnProperty('parent') &&
  //   values.parent !== null &&
  //   values.parent !== '' &&
  //   values.hasOwnProperty('pan') &&
  //   values.pan !== null &&
  //   values.pan !== '' &&
  //   values.hasOwnProperty('district') &&
  //   values.district !== null &&
  //   values.district !== '' &&
  //   values.hasOwnProperty('line1') &&
  //   values.line1 !== null &&
  //   values.line1 !== '' &&
  //   values.hasOwnProperty('country') &&
  //   values.country !== null &&
  //   values.country !== '' &&
  //   values.hasOwnProperty('state') &&
  //   values.state !== null &&
  //   values.state !== '' &&
  //   values.hasOwnProperty('pin') &&
  //   values.pin !== null &&
  //   values.pin !== '' &&
  //   values.hasOwnProperty('phone') &&
  //   values.phone !== null &&
  //   values.phone !== ''
  // ) {
  //   isButtonDisabled = false;
  // } else {
  //   isButtonDisabled = true;
  // }

  const [loading, setLoading] = useState(false);

  const handleModifyProfile = async () => {
    setLoading(true);

    const payload = {
      email: profile?.email,
      payload: {
        gender_id: formik.values.gender,
        first_name: formik.values.firstName,
        last_name: formik.values.lastName,
        pan: formik.values.pan,
        relationship_name: formik.values.parent,
        account_type_id: 1,
        date_of_birth: formik.values.dob,
        phone: formik.values.phone,
        addresses: [
          {
            address_line1: formik.values.line1,
            address_line2: formik.values.line2,
            city: formik.values.city,
            district: formik.values.district,
            country: formik.values.country,
            state: formik.values.state,
            pincode: formik.values.pin,
          },
        ],
      },
    };
    await dispatch(patchProfile(payload));
    setLoading(false);
    dispatch(getProfile(signInSession?.email));
  };
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const toInputUppercase = (e) => {
    e.target.value = `${e.target.value}`.toUpperCase();
  };

  return (
    <form autoComplete="off" noValidate {...props}>
      {!profileUpdate?.loading && profileUpdate?.updateProfile?.length > 0 && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          bodyStyle={{ backgroundColor: 'teal', color: 'coral' }}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {profileUpdate?.updateProfile[0]?.message}
          </Alert>
        </Snackbar>
      )}
      {!profileUpdate?.loading && profileUpdate?.error && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          bodyStyle={{ backgroundColor: 'teal', color: 'coral' }}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {profileUpdate?.error}
          </Alert>
        </Snackbar>
      )}
      <Card>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="First name"
                name="firstName"
                onChange={formik.handleChange}
                error={IsErrorMethod('firstName')}
                helperText={IsErrorMethod('firstName') && formik.errors.firstName}
                onBlur={formik.handleBlur}
                required
                value={formik.values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last name"
                name="lastName"
                onChange={formik.handleChange}
                error={IsErrorMethod('lastName')}
                helperText={IsErrorMethod('lastName') && formik.errors.lastName}
                onBlur={formik.handleBlur}
                required
                value={formik.values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                onChange={formik.handleChange}
                error={IsErrorMethod('email')}
                helperText={IsErrorMethod('email') && formik.errors.email}
                onBlur={formik.handleBlur}
                required
                disabled
                value={formik.values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phone"
                onChange={formik.handleChange}
                error={IsErrorMethod('phone')}
                helperText={IsErrorMethod('phone') && formik.errors.phone}
                onBlur={formik.handleBlur}
                type="number"
                value={formik.values.phone}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              {' '}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <DesktopDatePicker
                    label="DOB"
                    inputFormat="YYYY/MM/DD"
                    name={'dob'}
                    value={formik.values.dob}
                    onChange={(value) => {
                      const dt = moment(new Date(value)).format('yyyy/MM/DD');

                      formik.setFieldValue(`dob`, dt.replaceAll('/', '-'));
                    }}
                    error={IsErrorMethod('dob')}
                    helperText={IsErrorMethod('dob') && formik.errors.dob}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => <TextField {...params} />}
                  />{' '}
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label" required>
                  Sex
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="gender"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  error={IsErrorMethod('gender')}
                  helperText={IsErrorMethod('gender') && formik.errors.gender}
                  onBlur={formik.handleBlur}
                >
                  <FormControlLabel value="2" control={<Radio />} label="Female" />
                  <FormControlLabel value="1" control={<Radio />} label="Male" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Spouse/Parent Name"
                name="parent"
                onChange={formik.handleChange}
                error={IsErrorMethod('parent')}
                helperText={IsErrorMethod('parent') && formik.errors.parent}
                onBlur={formik.handleBlur}
                value={formik.values.parent}
                required
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="PAN"
                name="pan"
                value={formik.values.pan}
                inputProps={{ maxLength: 10 }}
                onChange={formik.handleChange}
                error={IsErrorMethod('pan')}
                helperText={IsErrorMethod('pan') && formik.errors.pan}
                onBlur={formik.handleBlur}
                onInput={toInputUppercase}
                required
                variant="outlined"
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Account Number"
                name="account"
                value={values.account}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid> */}
          </Grid>
        </CardContent>
        <Divider />
        <CardHeader subheader="The information can be edited" title="Address" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Address Line 1"
                name="line1"
                onChange={formik.handleChange}
                error={IsErrorMethod('line1')}
                helperText={IsErrorMethod('line1') && formik.errors.line1}
                onBlur={formik.handleBlur}
                required
                value={formik.values.line1}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Address Line 2"
                name="line2"
                onChange={formik.handleChange}
                error={IsErrorMethod('line2')}
                helperText={IsErrorMethod('line2') && formik.errors.line2}
                onBlur={formik.handleBlur}
                value={formik.values.line2}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="City"
                name="city"
                onChange={formik.handleChange}
                error={IsErrorMethod('city')}
                helperText={IsErrorMethod('city') && formik.errors.city}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="District"
                name="district"
                onChange={formik.handleChange}
                error={IsErrorMethod('district')}
                helperText={IsErrorMethod('district') && formik.errors.district}
                onBlur={formik.handleBlur}
                required
                value={formik.values.district}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="State"
                name="state"
                onChange={formik.handleChange}
                error={IsErrorMethod('state')}
                helperText={IsErrorMethod('state') && formik.errors.state}
                onBlur={formik.handleBlur}
                required
                value={formik.values.state}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Country"
                name="country"
                onChange={formik.handleChange}
                error={IsErrorMethod('country')}
                helperText={IsErrorMethod('country') && formik.errors.country}
                onBlur={formik.handleBlur}
                required
                value={formik.values.country}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Pin"
                name="pin"
                onChange={formik.handleChange}
                error={IsErrorMethod('pin')}
                helperText={IsErrorMethod('pin') && formik.errors.pin}
                onBlur={formik.handleBlur}
                type="number"
                required
                value={formik.values.pin}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
          }}
        >
          <button
            className="button button--effect"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              formik.submitForm();
            }}
            // disabled={isButtonDisabled}
            disabled={!formik.dirty || loading}
          >
            Save details {loading && <CircularProgress size={'1.5rem'} sx={{ color: '#777' }} />}
          </button>
        </Box>
      </Card>
    </form>
  );
};
