import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

/* initial State Object */
const initialStateObject = {
  loading: true,
  profile: [],
  error: null,
};

/* Get Profile List API Url */
const getProfileApiUrl = `${process.env.REACT_APP_API_BASE_URL}user-management/users/email/`;

/* Get Profile List */
export const getProfile = createAsyncThunk('profile/fetchProfile', (email) =>
  axios.get(`${getProfileApiUrl + email}/user-profile`).then((response) => response.data)
);

const productSlice = createSlice({
  name: 'profile',
  initialState: initialStateObject,
  extraReducers: (builder) => {
    builder.addCase(getProfile.pending, (state) => {
      state.loading = true;
      state.profile = [];
      state.error = null;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.profile = action.payload;
      state.error = null;
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.loading = false;
      state.profile = [];
      state.error = action.error.message;
    });
  },
});

export default productSlice.reducer;
