import { Navigate, useRoutes } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import UserPage from './pages/UserPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import ActivityPage from './pages/ActivityPage';
import AccountsPage from './pages/AccountsPage';
import Settings from './pages/settings';
import InvestmentsPage from './pages/InvestmentsPage';
import LoginPage from './pages/LoginPage';
import SignUp from './pages/sign-up';
import ConfirmSignUp from './pages/confirm-sign-up';
import { RouteAuthentication } from './components/route-authentication/route-authentication.auth';
import Properties from './pages/properties/properties-page';
import Notification from './pages/notification';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        {
          path: 'app',
          element: (
            <RouteAuthentication>
              <DashboardAppPage />
            </RouteAuthentication>
          ),
        },
        {
          path: 'user',
          element: (
            <RouteAuthentication>
              <UserPage />
            </RouteAuthentication>
          ),
        },
        {
          path: 'activity',
          element: (
            <RouteAuthentication>
              <ActivityPage />
            </RouteAuthentication>
          ),
        },
        {
          path: 'accounts',
          element: (
            <RouteAuthentication>
              <AccountsPage />{' '}
            </RouteAuthentication>
          ),
        },
        {
          path: 'investments',
          element: (
            <RouteAuthentication>
              <InvestmentsPage />
            </RouteAuthentication>
          ),
        },
        {
          path: 'settings',
          element: (
            <RouteAuthentication>
              <Settings />
            </RouteAuthentication>
          ),
        },
        {
          path: 'properties',
          element: (
            <RouteAuthentication>
              <Properties />
            </RouteAuthentication>
          ),
        },
        { path: 'notification', element: <RouteAuthentication><Notification /></RouteAuthentication> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: 'login', element: <LoginPage /> },
        { path: 'sign-up', element: <SignUp /> },
        { path: 'confirm-sign-up', element: <ConfirmSignUp /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
