import { Box, Container, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../store/profile.slice';
import { AccountProfile } from './account/account-profile';
import { AccountProfileDetails } from './account/account-profile-details';

const Page = () => {
  const dispatch = useDispatch();

  /* Get the State of the profile slice */
  const profileState = useSelector((state) => state.profile);
  const { profile } = profileState;

  /* profile API GET Call */
  useEffect(() => {
    if (profile.length !== 0) dispatch(getProfile(profile.email));
  }, []);

  return (
    <>
      <title>Investments</title>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Typography sx={{ mb: 3 }} variant="h4">
            Account
          </Typography>
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <AccountProfile email={profile.length !== 0 ? profile.email : ''} />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <AccountProfileDetails />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Page;
