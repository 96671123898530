/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Typography, Box, Modal, TextField, Alert, CircularProgress } from '@mui/material';

import * as React from 'react';
import { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaMapMarkerAlt, FaWindowClose } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { postPropertyInterest } from '../../store/products.slice';
import './styles/globals.scss';
import propertyPlaceholder from '../../assets/property-placeholder.webp';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  // bgcolor: 'background.paper',
  // border: '2px solid #000',
  // boxShadow: 24,
  height: '90%',
  p: 4,
};

const PropertyCard = ({ product }) => {
  const [open, setOpen] = React.useState(false);
  const [opendetails, setOpenDetails] = React.useState(false);

  const [alert, setAlert] = useState({ show: false, loading: false, message: '', status: 'success' });
  const [openImages, setOpenImages] = React.useState(false);
  const handleImagesOpenDetails = () => setOpenImages(true);
  const handleImagesCloseDetails = () => setOpenImages(false);

  const handleOpen = () => {
    setOpen(true);
    setOpenDetails(false);
  };
  const handleClose = () => setOpen(false);
  const handleOpenDetails = () => setOpenDetails(true);
  const handleCloseDetails = () => setOpenDetails(false);

  const dispatch = useDispatch();

  const userId = useSelector((state) => state.profile.profile.user_id);
  const [messages, setMessage] = useState('');

  const postInterest = async () => {
    setAlert({ ...alert, show: false, loading: true });

    const payload = {
      property_id: product.property_id,
      user_id: userId,
      message: messages,
    };
    const response = await dispatch(postPropertyInterest(payload));
    if (response?.payload) {
      setAlert({
        show: true,
        loading: false,
        message: 'Thank you for showing interest in our property!',
        status: 'success',
      });
    } else if (response?.error?.message?.split(' ')[response?.error?.message?.split(' ').length - 1] === '409') {
      setAlert({ show: true, loading: false, message: 'Your interest is already recorded', status: 'warning' });
    } else {
      setAlert({ show: true, loading: false, message: 'Unexpected error occured', status: 'error' });
    }

    setTimeout(() => {
      setMessage('');
      setAlert({ show: false, loading: false, message: '', status: 'success' });
      setOpen(false);
    }, 3000);
  };
  const settings = {
    dots: true, // Show dots navigation
    infinite: true, // Enable infinite scrolling
    speed: 50, // Transition speed in milliseconds
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at once
  };

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
            <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:max-w-xl">
              <Box sx={{ cursor: 'pointer', marginTop: '15px' }} onClick={() => handleClose()}>
                <FaWindowClose />
              </Box>
              <h1 className="text-3xl font-bold text-center text-stone-900 uppercase">{product.title}</h1>
              <h4 className="  text-center text-stone-900 ">Thank you for showing interest in our property!</h4>
              <form className="mt-6">
                <div className="mb-2 mt-2">
                  <TextField
                    fullWidth
                    label="Message"
                    name="email"
                    multiline
                    rows={4}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    value={messages}
                    variant="outlined"
                  />
                </div>
                {alert.show && (
                  <Alert
                    onClose={() => setAlert({ show: false, loading: false, message: '', status: 'success' })}
                    severity={alert.status || 'success'}
                    sx={{ width: '100%' }}
                  >
                    {alert.message || ''}
                  </Alert>
                )}
                <div className="mt-6">
                  <button
                    type="button"
                    className="button button--effect w-full px-4 py-2 bg-amber-300 rounded-md "
                    onClick={() => postInterest()}
                    disabled={alert.loading || alert.show}
                  >
                    {alert.loading ? <CircularProgress size={'1.5rem'} sx={{ color: '#777' }} /> : 'Show Interest'}
                  </button>
                </div>
              </form>{' '}
            </div>
          </div>
        </Modal>
      </div>
      <div>
        <Modal
          open={opendetails}
          onClose={handleCloseDetails}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
            <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:max-w-xl">
              <Box sx={{ cursor: 'pointer', marginTop: '15px' }} onClick={() => handleCloseDetails()}>
                <FaWindowClose />
              </Box>
              <h3 className="text-3xl font-bold text-center text-stone-900 capitalize">{product.title}</h3>
              <h5 className="text-l text-center text-stone-800 capitalize">{product.subtitle}</h5>

              <div className="property__grid__single m-2 p-6 text-center">
                <Box
                  sx={{ display: 'flex', fontWeight: '500', fontSize: '1rem', pt: 1 }}
                  className=" d-flex align-items-center gap-1"
                >
                  <FaMapMarkerAlt /> {`${product.state}, ${product.country}`}
                </Box>
                <div className="property__grid__single__inner">
                  <Box sx={{ display: 'flex' }}>
                    <div dangerouslySetInnerHTML={{ __html: product.description }} className={'itemStyles p-1'} />
                  </Box>

                  <div className="item__info">
                    <div className="item__info__single">
                      <>
                        <p>Price</p>
                        <h6>{`${product.price} ₹`}</h6>
                      </>
                    </div>

                    <div className="item__info__single">
                      <>
                        <p>Area Size</p>
                        <h6>{`${product.area_size}` || '-'}</h6>
                      </>
                    </div>
                  </div>
                </div>
                <div className="invest__cta">
                  <Link to="" onClick={handleOpen} className="button button--effect">
                    {' '}
                    Show interest
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div>
        <Modal
          open={openImages}
          onClose={handleImagesCloseDetails}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
            <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:max-w-xl">
              <Box sx={{ cursor: 'pointer', marginTop: '15px' }} onClick={() => handleImagesCloseDetails()}>
                <FaWindowClose />
              </Box>

              <h3 className="text-3xl font-bold text-center text-stone-900 capitalize">{product.title}</h3>
              <h5 className="text-l text-center text-stone-800 capitalize">{product.subtitle}</h5>

              <div className="property__grid__single m-2 p-6 text-center">
                {/* <Slider {...settings}> */}
                {product?.image_urls !== null ? (
                  product?.image_urls.map((image, index) => (
                    <div key={index} className="img__effect row d-flex align-items-center">
                      <img src={image.url} alt={`Image ${index + 1}`} width={'500px'} height={'300px'} />
                    </div>
                  ))
                ) : (
                  <Box
                    sx={{
                      width: '500px',
                      height: '500px',
                      backgroundImage: `url(${propertyPlaceholder})`,

                      backgroundSize: 'cover',
                    }}
                  />
                )}
                {/* </Slider> */}
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div className="property__grid__single">
        <div className="img__effect cursor-pointer" onClick={handleImagesOpenDetails}>
          <Slider {...settings}>
            {product?.image_urls !== null ? (
              product?.image_urls.map((image, index) => (
                <div key={index}>
                  <Box
                    sx={{
                      width: '100%',
                      height: '12rem',
                      backgroundImage: `url(${image.url})`,

                      backgroundSize: 'cover',
                    }}
                  />
                </div>
              ))
            ) : (
              <Box
                sx={{
                  width: '100%',
                  height: '12rem',
                  backgroundImage: `url(${propertyPlaceholder})`,

                  backgroundSize: 'cover',
                }}
              />
            )}
          </Slider>
        </div>
        <div className="property__grid__single__inner">
          <Box sx={{ display: 'flex' }}>
            <Typography
              sx={{ fontWeight: 'bold', fontSize: '1.5rem', pt: 2, cursor: 'pointer' }}
              onClick={handleOpenDetails}
            >
              {product.title?.slice(0, 20)}
              {product.title?.length > 20 && '...'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ fontSize: '1rem', pt: 2, cursor: 'pointer' }} onClick={handleOpenDetails}>
              {product.subtitle?.slice(0, 30)}
              {product.subtitle?.length > 30 && '...'}
            </Typography>
          </Box>
          <Box
            sx={{ display: 'flex', fontWeight: '500', fontSize: '1rem', pt: 1 }}
            className=" d-flex align-items-center gap-1"
          >
            <FaMapMarkerAlt /> {`${product.state}, ${product.country}`}
          </Box>

          <div className="item__info">
            <div className="item__info__single">
              {/* {share_price ? (
              <>
                <p>Share Price</p>
                <h6>{share_price + ' ₹'}</h6>
              </>
            ) : ( */}
              <>
                <p>Price</p>
                <h6>{`${product.price} ₹`}</h6>
              </>
              {/* )} */}
            </div>

            <div className="item__info__single">
              {/* {rate_of_dividend ? (
              <>
                <p>Rate of Divident</p>
                <h6>{rate_of_dividend || '-'}</h6>
              </>
            ) : ( */}
              <>
                <p>Area Size</p>
                <h6>{`${product.area_size}` || '-'}</h6>
              </>
              {/* )} */}
            </div>
          </div>
          <div className="invest__cta__wrapper">
            {/* <div className='countdown__wrapper'>
            <p className='secondary d-flex align-items-center gap-1'>
              <FaClock /> Left to invest
            </p>
            -
          </div> */}
            <div className="invest__cta">
              <Link to="" onClick={handleOpen} className="button button--effect">
                {' '}
                Show interest
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyCard;
