import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

/* initial State Object */
const initialStateObject = {
  loading: true,
  updateProfile: [],
  error: null,
};

const patchProfileApiUrl = `${process.env.REACT_APP_API_BASE_URL}user-management/users/email/`;

/* Post Application review */
export const patchProfile = createAsyncThunk('products/patchProfile', (payload) => {
  return axios
    .patch(`${patchProfileApiUrl + payload.email}/user-profile`, payload.payload)
    .then((response) => response.data);
});

const updateProductSlice = createSlice({
  name: 'updateProfile',
  initialState: initialStateObject,
  reducers: {
    resetUpdateProfile: (state) => {
      state.loading = false;
      state.updateProfile = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(patchProfile.pending, (state) => {
      state.loading = true;
      state.updateProfile = [];
      state.error = null;
    });
    builder.addCase(patchProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.updateProfile = [action.payload];
      state.error = null;
    });
    builder.addCase(patchProfile.rejected, (state, action) => {
      state.loading = false;
      state.updateProfile = [];
      state.error = action.error.message;
    });
  },
});

export default updateProductSlice.reducer;
export const { resetUpdateProfile } = updateProductSlice.actions;
