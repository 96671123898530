import ReactDOM from 'react-dom/client';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { Amplify, Auth } from 'aws-amplify';
import axios from 'axios';
import store from './redux/store';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './pages/properties/styles/globals.scss';
import awsExports from './awsmobile';
import { getProfile } from './store/profile.slice';
import { signIn } from './store/sign-in.slice';

Amplify.configure(awsExports);
Auth.configure(awsExports);
let userAttributes;

axios.interceptors.request.use(
  async (req) => {
    await Auth.currentSession()
      .then((res) => {
        req.headers.Authorization = `Bearer ${res.accessToken.jwtToken}`;
      })
      .catch((err) => {
        console.log(err);
      });

    return req;
  },
  (err) => {
    console.log(err);
    return Promise.reject(err);
  }
);
// Checks the current user details
await Auth.currentAuthenticatedUser()
  .then(async (res) => {
    userAttributes = res.attributes;
    const payload = {
      userAttributes: res.attributes,
      isLoggedIn: true,
    };
    store.dispatch(signIn(payload));
    if (userAttributes.email != null) {
      await store.dispatch(getProfile(userAttributes.email));
    }
  })
  .catch((err) => {
    console.log(err);
  });
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.setItem('logout-event', 'logout');
      // If session is not available
      // Auth.signOut();
    }
    return Promise.reject(error);
  }
);

const theme = createTheme({
  palette: {
    primary: {
      // main: '#2a3b8f',
      main: '#1554D5',
    },
    secondary: {
      // main: '#0b0c0e',
      main: '#101828',
    },
    text: {
      // primary: '#2a3b8f',
      secondary: '#6B7280',
    },
    background: {
      paper: '#FFFFFF',
      default: '#f1f5f9',
      // default: '#f3f3f8',
    },
    icon: {
      main: '#2e3a91',
    },
    info: {
      main: '#1554D5',
    },
  },
  option: {
    // Hover
    '&[data-focus="true"]': {
      backgroundColor: '#ffffff',
      borderColor: 'transparent',
    },
    // Selected
    '&[aria-selected="true"]': {
      backgroundColor: '#ffffff',
      borderColor: 'transparent',
    },
  },
});

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </HelmetProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
