import PropTypes from 'prop-types';
import { set, sub } from 'date-fns';
import { noCase } from 'change-case';
import { faker } from '@faker-js/faker';
import { useEffect, useState } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { getNotifications, postNotification, postSingleNotification } from '../../../store/notification.slice';
import store from '../../../redux/store';

// ----------------------------------------------------------------------

// const NOTIFICATIONS = [
//   {
//     id: faker.datatype.uuid(),
//     title: 'Your investment is processing',
//     description: 'waiting for approval',
//     avatar: null,
//     type: 'mail',
//     createdAt: set(new Date(), { hours: 10, minutes: 30 }),
//     is_read: true,
//   },

//   {
//     id: faker.datatype.uuid(),
//     title: 'Investment Processed',
//     description: 'Your order is processed',
//     avatar: null,
//     type: 'order_placed',
//     createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
//     is_read: false,
//   },
// ];

export default function NotificationsPopover() {
  const [open, setOpen] = useState(null);

  const dispatch = useDispatch();

  const stateElements = store.getState();

  const userId = stateElements.profile.profile.user_id;

  const payload = {
    userId,
    rowsPerPage: 999,
    page: 0,
  };

  useEffect(() => {
    dispatch(getNotifications(payload));
  }, []);
  const notificationState = useSelector((state) => state.notifications);
  const { notificationList } = notificationState;
  const NOTIFICATIONS = notificationList?.items;
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const [notifications, setNotifications] = useState(NOTIFICATIONS);

  const totalUnRead = notifications?.filter((item) => item.is_read === false).length;
  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    setNotifications(NOTIFICATIONS);
  }, [NOTIFICATIONS]);
  const handleMarkAllAsRead = async () => {
    const res = await dispatch(postNotification(userId));
    setOpen(null);
    dispatch(getNotifications(payload));
    // setNotifications(
    //   notifications.map((notification) => ({
    //     ...notification,
    //     is_read: false,
    //   }))
    // );
  };

  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Latest
              </ListSubheader>
            }
          >
            {notifications?.slice(0, 2).map((notification) => (
              <NotificationItem key={notification.notification_id} notification={notification} />
            ))}
          </List>

          {notifications?.length > 2 && (
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  Older Notifications
                </ListSubheader>
              }
            >
              {notifications?.slice(2, 5).map((notification) => (
                <NotificationItem key={notification.notification_id} notification={notification} />
              ))}
            </List>
          )}
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {notifications?.length > 5 && <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple component='a' href='/dashboard/notification'>
            View All
          </Button>
        </Box>}
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    is_read: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification }) {
  const { avatar, title } = renderContent(notification);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(null);
  const stateElements = store.getState();
  const userId = stateElements.profile.profile.user_id;

  const handleSingleRead = async (event, notificationId) => {
    const payload = {
      userId,
      notificationId,
      rowsPerPage: 999,
      page: 0,
    };
    await dispatch(postSingleNotification(payload));
    setOpen(null);
    dispatch(getNotifications(payload));
  };
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.is_read && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={(event) => handleSingleRead(event, notification.notification_id)}
    >
      <ListItemAvatar>
        {notification?.icon_type === 'USER' && (
          <Avatar
            sx={{ bgcolor: 'background.neutral', height: '23px', width: '23px' }}
            src="/assets/images/avatars/avatar_2.jpg"
          >
            {avatar}
          </Avatar>
        )}
        {notification?.icon_type === 'APPROVED' && (
          <Avatar
            sx={{ bgcolor: 'background.neutral', height: '23px', width: '23px' }}
            src="/assets/icons/ic_notification_package.svg"
          >
            {avatar}
          </Avatar>
        )}
        {notification?.icon_type === 'REJECTED' && (
          <Avatar
            sx={{ bgcolor: 'background.neutral', height: '23px', width: '23px' }}
            src="/assets/icons/alert-triangle-fill.svg"
          >
            {avatar}
          </Avatar>
        )}
        {notification?.icon_type === 'PROCESSING' && (
          <Avatar
            sx={{ bgcolor: 'background.neutral', height: '23px', width: '23px' }}
            src="/assets/icons/ic_notification_shipping.svg"
          >
            {avatar}
          </Avatar>
        )}
        {notification?.icon_type === 'PROPERTY' && (
          <Avatar
            sx={{ bgcolor: 'background.neutral', height: '23px', width: '23px' }}
            src="/assets/icons/ic_notification_mail.svg"
          >
            {avatar}
          </Avatar>
        )}
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.notification_created_at)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <>
      <Typography variant="subtitle2">{notification.title}</Typography>{' '}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        {notification.description}
      </Typography>
    </>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_package.svg" />,
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_shipping.svg" />,
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_mail.svg" />,
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_chat.svg" />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
