/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-key */
/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-template */

import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputLabel,
  TextField,
  Card,
  Stack,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { documentUpload, postApplication, resetReviewProduct } from '../store/application_review';
import { selectProduct } from '../store/product-selection.slice';
import { getProducts } from '../store/products.slice';

const steps = ['Product', 'Applicants', 'Nominee', 'Payment'];
const applyingAs = [
  {
    value: '1',
    label: 'Individual',
  },
  {
    value: '2',
    label: 'Society / Trust',
  },
  {
    value: '3',
    label: 'Mutual Fund',
  },
  {
    value: '4',
    label: 'Company',
  },
  {
    value: '5',
    label: 'Others',
  },
];
const relation = [
  {
    relationship_type_id: 1,
    name: 'Spouse',
    description: '',
    created_at: '2023-01-23T18:59:43',
    updated_at: '2023-01-23T18:59:43',
    created_by: 'ADMIN',
    updated_by: 'ADMIN',
  },
  {
    relationship_type_id: 2,
    name: 'Father',
    description: '',
    created_at: '2023-01-23T18:59:43',
    updated_at: '2023-01-23T18:59:43',
    created_by: 'ADMIN',
    updated_by: 'ADMIN',
  },
  {
    relationship_type_id: 3,
    name: 'Mother',
    description: '',
    created_at: '2023-01-23T18:59:43',
    updated_at: '2023-01-23T18:59:43',
    created_by: 'ADMIN',
    updated_by: 'ADMIN',
  },
  {
    relationship_type_id: 4,
    name: 'Daughter',
    description: '',
    created_at: '2023-01-23T18:59:43',
    updated_at: '2023-01-23T18:59:43',
    created_by: 'ADMIN',
    updated_by: 'ADMIN',
  },
  {
    relationship_type_id: 5,
    name: 'Son',
    description: '',
    created_at: '2023-01-23T18:59:43',
    updated_at: '2023-01-23T18:59:43',
    created_by: 'ADMIN',
    updated_by: 'ADMIN',
  },
  {
    relationship_type_id: 6,
    name: 'Friend',
    description: '',
    created_at: '2023-01-23T18:59:43',
    updated_at: '2023-01-23T18:59:43',
    created_by: 'ADMIN',
    updated_by: 'ADMIN',
  },

  {
    relationship_type_id: 7,
    name: 'Other',
    description: '',
    created_at: '2023-01-23T18:59:43',
    updated_at: '2023-01-23T18:59:43',
    created_by: 'ADMIN',
    updated_by: 'ADMIN',
  },
];
const payment = [
  {
    payment_method_type_id: 1,
    name: 'Cheque Payment',
    description: '',
    created_at: '2023-01-23T18:59:43',
    updated_at: '2023-01-23T18:59:43',
    created_by: 'ADMIN',
    updated_by: 'ADMIN',
  },
  {
    payment_method_type_id: 2,
    name: 'Bank Transfer',
    description: '',
    created_at: '2023-01-23T18:59:43',
    updated_at: '2023-01-23T18:59:43',
    created_by: 'ADMIN',
    updated_by: 'ADMIN',
  },
];

const validationNomineeSchema = yup.object({
  nominees: yup.array().of(
    yup.object().shape({
      relationship_type_id: yup.string().required('Required'),
      name: yup.string().required('Required'),
      relationship_name: yup.string().required('Required'),
      sex: yup.string().required('Required'),
      nominee_percentage: yup.string().required('Required'),
      date_of_birth: yup.date().required('Required'),
      end_date: yup.date().required('Required'),
      aadhaar_number: yup
        .string()
        .required('Required')
        .matches(/^[0-9]+$/, 'Must be only digits')
        .min(12, 'Must be exactly 12 digits')
        .max(12, 'Must be exactly 12 digits'),
      pan: yup.string().matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "Doesn't look like a Pan card Number"),
      aadhardoc: yup.string().required('Required'),
      // pandoc: yup.string().required('Required'),
    })
  ),
});

const validationSchema = yup.object({
  applicants: yup.array().of(
    yup.object().shape({
      applicant_type_id: yup.string().required('Required'),
      name: yup.string().required('Required'),
      relationship_name: yup.string().required('Required'),
      sex: yup.string().required('Required'),
      date_of_birth: yup.date().required('Required'),
      end_date: yup.date().required('Required'),
      aadhaar_number: yup
        .string()
        .required('Required')
        .matches(/^[0-9]+$/, 'Must be only digits')
        .min(12, 'Must be exactly 12 digits')
        .max(12, 'Must be exactly 12 digits'),
      pan: yup
        .string()
        .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "Doesn't look like a Pan card Number")
        .required('Required'),
      aadhardoc: yup.string().required('Required'),
      pandoc: yup.string().required('Required'),
    })
  ),
});

export default function InvestmentsPage() {
  React.useEffect(() => {
    dispatch(getProducts());
  }, []);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const dispatch = useDispatch();

  const isStepSkipped = (step) => skipped.has(step);

  let isProductDisabled = true;
  let isApplicantDisabled = true;
  let isNomineeDisabled = true;
  let isPaymentDisabled = true;

  // eslint-disable-next-line prefer-const
  let globalPayload = {};

  const handleNext = () => {
    // let newSkipped = skipped;
    // if (isStepSkipped(activeStep)) {
    //   newSkipped = new Set(newSkipped.values());
    //   newSkipped.delete(activeStep);
    // }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // setSkipped(newSkipped);
  };

  /* Get the State of the Profile slice */
  const profileState = useSelector((state) => state.profile);
  const { profile } = profileState;

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /* Get the State of the Product slice */
  const selectedProductState = useSelector((state) => state.selectedProduct);
  const { selectedProduct } = selectedProductState;

  const [values, setValues] = React.useState({
    productName: selectedProduct.product_id,
    noOfShares: 0,
    valueOfOneShare: selectedProduct.share_price,
    // applicationMoney: 5000,
    applyingAs: '1',
    totalAmount: 0,
  });

  /* Get the State of the Product slice */
  const productListState = useSelector((state) => state.products);
  const { productList } = productListState;

  /* Get the State of the Product slice */
  const reviewState = useSelector((state) => state.applicationReview);
  const { applicationReview } = reviewState;

  const handlePChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleProductChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    const filteredData = productList?.items?.filter(
      (product) => product.product_id === parseInt(event.target.value, 10)
    );
    dispatch(selectProduct(filteredData[0]));
    setValues({
      ...values,
      productName: filteredData[0].product_id,
      valueOfOneShare: filteredData[0].share_price,
      noOfShares: 0,
    });
  };

  const handleSharesChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  React.useEffect(() => {
    setValues({
      ...values,
      totalAmount: parseInt(values.noOfShares, 10) * parseInt(values.valueOfOneShare, 10),
    });
  }, [values.noOfShares]);

  React.useEffect(() => {
    dispatch(resetReviewProduct());
  }, []);

  const handleProductNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  /* Check if the fields exists and are not empty */
  if (values.hasOwnProperty('noOfShares') && values.noOfShares !== 0 && values.noOfShares !== '') {
    isProductDisabled = false;
  } else {
    isProductDisabled = true;
  }

  /* Payment Details */
  const [valuesPayment, setPaymentValues] = React.useState({
    payment_type: '1',
    name: '',
    ref: '',
    amount: '',
    bankdate: '',
    dateOfTxn: '',
    dddate: '',
    dddateOfTxn: '',
    cheque_number: '',
  });
  const [dddate, setdddateValue] = React.useState(
    new Date().getFullYear() + '-' + parseInt(new Date().getMonth() + 1, 10) + '-' + new Date().getDate()
  );
  const [dddateOfTxn, setdddateOfTxnValue] = React.useState(
    new Date().getFullYear() + '-' + parseInt(new Date().getMonth() + 1, 10) + '-' + new Date().getDate()
  );
  const [bankdate, setdbankateValue] = React.useState(
    new Date().getFullYear() + '-' + parseInt(new Date().getMonth() + 1, 10) + '-' + new Date().getDate()
  );
  const [bankdateOfTxn, setdddateOfbankTxnValue] = React.useState(
    new Date().getFullYear() + '-' + parseInt(new Date().getMonth() + 1, 10) + '-' + new Date().getDate()
  );
  const handlePaymentChange = (event) => {
    setPaymentValues({
      ...valuesPayment,
      [event.target.name]: event.target.value,
    });
  };

  /* Check if the fields exists and are not empty */
  if (
    (valuesPayment.hasOwnProperty('name') &&
      valuesPayment.name !== null &&
      valuesPayment.name !== '' &&
      valuesPayment.hasOwnProperty('ref') &&
      valuesPayment.ref !== null &&
      valuesPayment.ref !== '') ||
    (valuesPayment.hasOwnProperty('cheque_number') &&
      valuesPayment.cheque_number !== null &&
      valuesPayment.cheque_number !== '')
  ) {
    isPaymentDisabled = false;
  } else {
    isPaymentDisabled = true;
  }

  const toInputUppercase = (e) => {
    e.target.value = `${e.target.value}`.toUpperCase();
  };

  const handlePaymentddDateChange = (newValue) => {
    setdddateValue(
      new Date(newValue).getFullYear() +
        '-' +
        parseInt(new Date(newValue).getMonth() + 1, 10) +
        '-' +
        new Date(newValue).getDate()
    );
  };
  const handlePaymentTxnDateChange = (newValue) => {
    setdddateOfTxnValue(
      new Date(newValue).getFullYear() +
        '-' +
        parseInt(new Date(newValue).getMonth() + 1, 10) +
        '-' +
        new Date(newValue).getDate()
    );
  };
  const handlePaymentbankDateChange = (newValue) => {
    setdbankateValue(
      new Date(newValue).getFullYear() +
        '-' +
        parseInt(new Date(newValue).getMonth() + 1, 10) +
        '-' +
        new Date(newValue).getDate()
    );
  };
  const handlePaymentbankTxnDateChange = (newValue) => {
    setdddateOfbankTxnValue(
      new Date(newValue).getFullYear() +
        '-' +
        parseInt(new Date(newValue).getMonth() + 1, 10) +
        '-' +
        new Date(newValue).getDate()
    );
  };

  const handlePaymentNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    const payload = {
      userId: profile.user_id,
      payload: {
        application_product: {
          product_id: values.productName,
          no_of_shares: values.noOfShares,
          status: 'PENDING',
          share_price: values.valueOfOneShare,
          total_price: values.totalAmount,
          created_at: Date.now(),
          updated_at: Date.now(),
          account_type_id: values.applyingAs,
        },

        applicants: formik.values.applicants,
        nominee: formikNominees.values.nominees,
        payment:
          valuesPayment.payment_type === '1'
            ? {
                payment_method_type_id: '1',
                amount: values.totalAmount,
                created_at: Date.now(),
                updated_at: Date.now(),
                created_by: profile?.first_name + ' ' + profile?.last_name,
                updated_by: profile?.first_name + ' ' + profile?.last_name,
                payment_date_received: dddate,
                cheque_details: {
                  cheque_number: valuesPayment.cheque_number,
                  date_drawn: dddateOfTxn,
                },
              }
            : {
                payment_method_type_id: '2',
                amount: values.totalAmount,
                created_at: Date.now(),
                updated_at: Date.now(),
                created_by: profile?.first_name + ' ' + profile?.last_name,
                updated_by: profile?.first_name + ' ' + profile?.last_name,
                payment_date_received: bankdate,
                bank_details: {
                  bank_name: valuesPayment.name,
                  date_of_transfer: bankdateOfTxn,
                  ref_number: valuesPayment.ref,
                },
              },
      },
    };

    dispatch(postApplication(payload));
  };
  const navigate = useNavigate();
  const handleReset = () => {
    setActiveStep(0);
    if (applicationReview.length > 0) navigate('/dashboard/app');
  };

  const [open, setOpen] = React.useState(true);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // Applicant's new section

  //  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      applicants: [
        {
          name: '',

          relationship_name: '',
          date_of_birth:
            parseInt(new Date().getFullYear() - 18, 10) +
            '-' +
            parseInt(new Date().getMonth() + 1, 10) +
            '-' +
            new Date().getDate(),
          aadhaar_number: '',
          pan: '',
          applicant_type_id: '1',
          gender_type_id: 1,
          aadhardoc: '',
          pandoc: '',
        },
      ],
    },
    validationSchema,
    onSubmit: (_values) => {
      postData(_values);
    },
  });

  /* Check if the fields exists and are not empty */
  if (
    formik.values.applicants[formik.values.applicants.length - 1].first_name !== '' &&
    formik.values.applicants[formik.values.applicants.length - 1].relationship_name !== '' &&
    formik.values.applicants[formik.values.applicants.length - 1].pan !== '' &&
    formik.values.applicants[formik.values.applicants.length - 1].aadhardoc !== '' &&
    formik.values.applicants[formik.values.applicants.length - 1].pandoc !== '' &&
    (formik.values.applicants[formik.values.applicants.length - 1].aadhaar_number !== null ||
      formik.values.applicants[formik.values.applicants.length - 1].aadhaar_number !== 0)
  ) {
    isApplicantDisabled = false;
  } else {
    isApplicantDisabled = true;
  }
  const [alert, setAlert] = React.useState({
    status: 'success',
    message: 'Successfully updated data',
    show: false,
    loading: false,
  });

  const postData = async (_values) => {
    setAlert({ ...alert, loading: true });
  };

  const IsErrorMethod = (type, _index) => {
    if (
      formik.touched.applicants &&
      formik.errors.applicants &&
      formik.touched.applicants[_index] &&
      formik.errors.applicants[_index]
    )
      return formik.touched.applicants[_index][type] && type in formik.errors.applicants[_index];
  };

  const saveAll = () => {
    for (let i = 0; i < formik.values.applicants.length; i++) {
      if (formik.values.applicants[i]?.aadhardoc !== null && formik.values.applicants[i]?.pandoc !== null) {
        formik.values.applicants[i].documents = [
          {
            document_id: formik.values.applicants[i].aadhardoc,
            document_type_id: 4,
          },
          {
            document_id: formik.values.applicants[i].pandoc,
            document_type_id: 3,
          },
        ];

        formik.values.applicants[i].created_at = Date.now();
        formik.values.applicants[i].updated_at = Date.now();
        formik.values.applicants[i].created_by = profile?.first_name + ' ' + profile?.last_name;
        formik.values.applicants[i].updated_by = profile?.first_name + ' ' + profile?.last_name;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const addField = () => {
    formik.setFieldValue('applicants', [
      ...formik.values.applicants,
      {
        applicant_type_id: '1',
        name: '',

        relationship_name: '',
        date_of_birth:
          parseInt(new Date().getFullYear() - 18, 10) +
          '-' +
          parseInt(new Date().getMonth() + 1, 10) +
          '-' +
          new Date().getDate(),
        gender_type_id: 1,
        pan: '',
        aadhaar_number: null,
        aadhardoc: '',
        pandoc: '',
      },
    ]);
  };

  const removeApplicant = (index) => {
    let applicantsArrayTemp = [];
    applicantsArrayTemp = [...formik.values.applicants];
    applicantsArrayTemp.splice(index, 1);
    formik.setFieldValue('applicants', applicantsArrayTemp);
  };
  const removeNominee = (index) => {
    let nomineeArrayTemp = [];
    nomineeArrayTemp = [...formikNominees.values.nominees];
    nomineeArrayTemp.splice(index, 1);
    formikNominees.setFieldValue('nominees', nomineeArrayTemp);
  };

  const applicantType = [
    {
      applicant_type_id: 1,
      name: 'Sole/First',
      description: '',
      created_at: '2023-01-23T18:59:43',
      updated_at: '2023-01-23T18:59:43',
      created_by: 'ADMIN',
      updated_by: 'ADMIN',
    },
    {
      applicant_type_id: 2,
      name: 'Second',
      description: '',
      created_at: '2023-01-23T18:59:43',
      updated_at: '2023-01-23T18:59:43',
      created_by: 'ADMIN',
      updated_by: 'ADMIN',
    },
    {
      applicant_type_id: 3,
      name: 'Third',
      description: '',
      created_at: '2023-01-23T18:59:43',
      updated_at: '2023-01-23T18:59:43',
      created_by: 'ADMIN',
      updated_by: 'ADMIN',
    },
  ];

  const panChangedHandler = async (event, index) => {
    formik.setFieldValue(`applicants.${index}.panLoading`, true);
    const bodyFormData = new FormData();
    await bodyFormData.append('file', event.target.files[0]);

    // data for file upload API
    const payload = {
      userId: profile?.user_id,
      file_content: bodyFormData,
    };

    const response = await dispatch(documentUpload(payload));
    formik.setFieldValue(`applicants.${index}.pandoc`, response?.payload?.document_id);
    formik.setFieldValue(`applicants.${index}.panLoading`, false);
    formik.setFieldValue(`applicants.${index}.panName`, event.target.files[0].name);
  };
  // function handle when we change file
  const aadharChangedHandler = async (event, index) => {
    formik.setFieldValue(`applicants.${index}.aadharLoading`, true);
    const bodyFormData = new FormData();
    bodyFormData.append('file', event.target.files[0]);

    // data for file upload API
    const payload = {
      userId: profile?.user_id,
      file_content: bodyFormData,
    };

    const response = await dispatch(documentUpload(payload));
    formik.setFieldValue(`applicants.${index}.aadhardoc`, response?.payload?.document_id);
    formik.setFieldValue(`applicants.${index}.aadharLoading`, false);
    formik.setFieldValue(`applicants.${index}.aadharName`, event.target.files[0].name);
  };

  // Nomineenew section
  const formikNominees = useFormik({
    enableReinitialize: true,
    initialValues: {
      nominees: [
        {
          name: '',
          relationship_name: '',
          date_of_birth: '2022-11-10',
          aadhaar_number: '',
          pan: '',
          relationship_type_id: '1',
          gender_type_id: 1,
          aadhardoc: '',
          pandoc: '',
          nominee_percentage: '',
        },
      ],
    },
    validationSchema: validationNomineeSchema,
    onSubmit: (_values) => {
      postNomineeData(_values);
    },
  });

  const postNomineeData = async (_values) => {
    setAlert({ ...alert, loading: true });
  };

  const IsErrorNomineeMethod = (type, _index) => {
    if (
      formikNominees.touched.nominees &&
      formikNominees.errors.nominees &&
      formikNominees.touched.nominees[_index] &&
      formikNominees.errors.nominees[_index]
    )
      return formikNominees.touched.nominees[_index][type] && type in formikNominees.errors.nominees[_index];
  };

  const saveAllNominee = () => {
    for (let i = 0; i < formikNominees.values.nominees.length; i++) {
      if (formikNominees.values.nominees[i]?.aadhardoc !== null && formikNominees.values.nominees[i]?.pandoc !== null) {
        formikNominees.values.nominees[i].documents = [
          {
            document_id: formikNominees.values.nominees[i].aadhardoc,
            document_type_id: 4,
          },
          {
            document_id: formikNominees.values.nominees[i].pandoc,
            document_type_id: 3,
          },
        ].filter((val) => val.document_id !== '');

        formikNominees.values.nominees[i].created_at = Date.now();
        formikNominees.values.nominees[i].updated_at = Date.now();
        formikNominees.values.nominees[i].created_by = profile?.first_name + ' ' + profile?.last_name;
        formikNominees.values.nominees[i].updated_by = profile?.first_name + ' ' + profile?.last_name;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const addNewField = () => {
    if (formikNominees.values.nominees.length < 2) {
      formikNominees.setFieldValue('nominees', [
        ...formikNominees.values.nominees,
        {
          relationship_type_id: '1',
          name: '',
          relationship_name: '',
          date_of_birth: '2022-11-10',
          gender_type_id: 1,
          pan: '',
          aadhaar_number: null,
          aadhardoc: '',
          pandoc: '',
          nominee_percentage: null,
        },
      ]);
    }
  };
  const panNomineeChangedHandler = async (event, index) => {
    formikNominees.setFieldValue(`nominees.${index}.panLoading`, true);
    const bodyFormData = new FormData();
    bodyFormData.append('file', event.target.files[0]);

    // data for file upload API
    const payload = {
      userId: profile?.user_id,
      file_content: bodyFormData,
    };

    const response = await dispatch(documentUpload(payload));
    formikNominees.setFieldValue(`nominees.${index}.pandoc`, response?.payload?.document_id);
    formikNominees.setFieldValue(`nominees.${index}.panLoading`, false);
    formikNominees.setFieldValue(`nominees.${index}.panName`, event.target.files[0].name);
  };
  // function handle when we change file
  const aadharNomineeChangedHandler = async (event, index) => {
    formikNominees.setFieldValue(`nominees.${index}.aadharLoading`, true);
    const bodyFormData = new FormData();
    bodyFormData.append('file', event.target.files[0]);

    // data for file upload API
    const payload = {
      userId: profile?.user_id,
      file_content: bodyFormData,
    };

    const response = await dispatch(documentUpload(payload));
    formikNominees.setFieldValue(`nominees.${index}.aadhardoc`, response?.payload?.document_id);
    formikNominees.setFieldValue(`nominees.${index}.aadharLoading`, false);
    formikNominees.setFieldValue(`nominees.${index}.aadharName`, event.target.files[0].name);
  };
  if (
    formikNominees.values.nominees[formikNominees.values.nominees.length - 1].name !== '' &&
    formikNominees.values.nominees[formikNominees.values.nominees.length - 1].relationship_name !== '' &&
    // formikNominees.values.nominees[formikNominees.values.nominees.length - 1].pan !== '' &&
    formikNominees.values.nominees[formikNominees.values.nominees.length - 1].aadhardoc !== '' &&
    // formikNominees.values.nominees[formikNominees.values.nominees.length - 1].pandoc !== '' &&
    formikNominees.values.nominees[formikNominees.values.nominees.length - 1].nominee_percentage !== '' &&
    (formikNominees.values.nominees[formikNominees.values.nominees.length - 1].aadhaar_number !== null ||
      formikNominees.values.nominees[formikNominees.values.nominees.length - 1].aadhaar_number !== 0)
  ) {
    isNomineeDisabled = false;
  } else {
    isNomineeDisabled = true;
  }

  return (
    <Box sx={{ px: 2, width: '100%' }}>
      {!reviewState?.loading && applicationReview?.length > 0 && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          bodyStyle={{ backgroundColor: 'teal', color: 'coral' }}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {'Successfully Applied'}
          </Alert>
        </Snackbar>
      )}
      {!reviewState?.loading && reviewState?.error && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          bodyStyle={{ backgroundColor: 'teal', color: 'coral' }}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {reviewState?.error.split(' ')[reviewState?.error.split(' ').length - 1] === '422'
              ? 'Validation error in the form'
              : 'Unexpected error occured'}
          </Alert>
        </Snackbar>
      )}
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          {reviewState.loading ? (
            <>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around', pt: 8 }}>
                <CircularProgress />
              </Box>
            </>
          ) : (
            <>
              {!reviewState?.error && (
                <Typography sx={{ py: 5, mt: 2, mb: 1 }} variant={'h4'}>
                  Please allow few days to update the account information in your account. The payment by Cheques and
                  Drafts are subject to realization!
                </Typography>
              )}
              {reviewState?.error && (
                <Typography sx={{ py: 5, mt: 2, mb: 1 }} variant={'h4'}>
                  Something went wrong with your Application. Please check the details you entered or try again later.
                </Typography>
              )}
            </>
          )}

          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Go back</Button>
          </Box>
        </>
      ) : activeStep === 0 ? (
        <>
          <Grid item lg={8} md={6} xs={12} pt={8}>
            <form autoComplete="off" noValidate>
              <Card>
                <CardHeader subheader="The information can be edited" title="Product Information" />
                <Box
                  sx={{
                    p: 2,
                  }}
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        autoFocus
                        fullWidth
                        label="Product name"
                        name="productName"
                        onChange={handleProductChange}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={values.productName}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        renderValue={
                          typeof values.productName !== 'undefined'
                            ? undefined
                            : () => <Typography sx={{ color: '#bbb' }}>Select team type</Typography>
                        }
                      >
                        <option hidden>
                          <Typography sx={{ color: '#bbb', fontSize: '0.1rem' }}>select product</Typography>
                        </option>
                        {productList?.items?.map((option) => (
                          <option key={option.product_id} value={option.product_id}>
                            {option.name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Number of shares"
                        name="noOfShares"
                        onChange={handleSharesChange}
                        required
                        type={'number'}
                        value={values.noOfShares}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Value of one share"
                        name="valueOfOneShare"
                        onChange={handlePChange}
                        required
                        disabled
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={values.valueOfOneShare}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Applying As"
                        name="applyingAs"
                        onChange={handlePChange}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={values.state}
                        variant="outlined"
                      >
                        {applyingAs.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2,
                  }}
                >
                  <InputLabel className="font-bold text-black">
                    Total Due on Application : {values.totalAmount}
                  </InputLabel>
                </Box>
              </Card>
            </form>
          </Grid>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            <Button onClick={handleProductNext} disabled={isProductDisabled}>
              {'Next'}
            </Button>
          </Box>
        </>
      ) : activeStep === 1 ? (
        <>
          <Grid item lg={8} md={6} xs={12} pt={8}>
            <form onSubmit={formik.handleApplicantNext}>
              <Box sx={{ display: 'flex' }}>
                <Typography sx={{ fontWeight: 'bold' }}>Applicant's Information</Typography>
              </Box>
              {formik.values.applicants.map((_item, _index) => (
                <Box key={_index}>
                  <Box
                    sx={{
                      my: 2,
                      p: 2,
                      display: 'block',
                    }}
                  >
                    {_index !== 0 && <Divider sx={{ mb: 2 }} />}
                    <Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 4 }}>
                        <Typography
                          fontWeight="bold"
                          sx={{ mb: 0.8, display: 'flex', justifyContent: 'space-between' }}
                        >
                          Applicant {_index + 1}
                          {_index !== 0 && (
                            <Typography
                              onClick={() => removeApplicant(_index)}
                              fontWeight="bold"
                              sx={{
                                ml: 3,
                                mb: 0.8,
                                display: 'flex',
                                justifyContent: 'space-between',
                                color: '#2c6dd4',
                                cursor: 'pointer',
                              }}
                            >
                              - Remove applicant
                            </Typography>
                          )}
                        </Typography>
                        <Box
                          sx={{
                            my: 1,
                            p: 2,
                          }}
                        />
                      </Box>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Select Applicant type"
                            name={`applicants.${_index}.applicant_type_id`}
                            onChange={(_value) => {
                              formik.handleChange(_value);
                            }}
                            required
                            select
                            SelectProps={{ native: true }}
                            value={formik.values.applicants[_index].applicant_type_id}
                            variant="outlined"
                          >
                            {applicantType.map((option) => (
                              <option key={option.applicant_type_id} value={option.applicant_type_id}>
                                {option.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            required
                            id="name"
                            label="Applicant's name"
                            variant="outlined"
                            placeholder="Applicant's name"
                            name={`applicants.${_index}.name`}
                            disabled={alert.loading || alert.show}
                            value={formik.values.applicants[_index].name}
                            onChange={(_value) => {
                              formik.handleChange(_value);
                            }}
                            error={IsErrorMethod('name', _index)}
                            helperText={IsErrorMethod('name', _index) && formik.errors.applicants[_index].name}
                            onBlur={formik.handleBlur}
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Box sx={{ pt: 2 }}>
                            <Typography fontWeight="bold" sx={{ mb: 0.8 }}>
                              Name of Spouse/Parent*
                            </Typography>
                            <TextField
                              required
                              placeholder="Name of Spouse/Parent*"
                              id="relationship_name"
                              variant="outlined"
                              disabled={alert.loading || alert.show}
                              name={`applicants.${_index}.relationship_name`}
                              value={formik.values.applicants[_index].relationship_name}
                              onChange={(_value) => {
                                formik.handleChange(_value);
                              }}
                              error={IsErrorMethod('relationship_name', _index)}
                              helperText={
                                IsErrorMethod('relationship_name', _index) &&
                                formik.errors.applicants[_index].relationship_name
                              }
                              onBlur={formik.handleBlur}
                              fullWidth
                            />
                          </Box>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Box sx={{ pt: 2 }}>
                            <FormControl>
                              <FormLabel id="demo-row-radio-buttons-group-label" required>
                                Sex
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name={`applicants.${_index}.gender_type_id`}
                                value={formik.values.applicants[_index].gender_type_id}
                                onChange={(_value) => {
                                  formik.handleChange(_value);
                                }}
                              >
                                <FormControlLabel value="2" control={<Radio />} label="Female" />
                                <FormControlLabel value="1" control={<Radio />} label="Male" />
                              </RadioGroup>
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid item md={12} xs={12}>
                          <Box sx={{ display: 'flex', gap: 2, pt: 2 }}>
                            <Box sx={{ width: '50%' }}>
                              <Typography fontWeight="bold" sx={{ mb: 0.8 }}>
                                DOB
                              </Typography>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  name={`applicants.${_index}.date_of_birth`}
                                  id="joining_date"
                                  views={['year', 'month', 'day']}
                                  value={formik.values.applicants[_index].date_of_birth}
                                  onChange={(value) => {
                                    const dt = moment(new Date(value)).format('yyyy/MM/DD');

                                    formik.setFieldValue(`applicants.${_index}.date_of_birth`, dt.replaceAll('/', '-'));
                                  }}
                                  maxDate={
                                    parseInt(new Date().getFullYear() - 18, 10) +
                                    '-' +
                                    parseInt(new Date().getMonth() + 1, 10) +
                                    '-' +
                                    new Date().getDate()
                                  }
                                  onBlur={formik.handleBlur}
                                  renderInput={(params) => <TextField fullWidth {...params} error={false} />}
                                />
                              </LocalizationProvider>
                            </Box>
                            <Grid item md={3} xs={12}>
                              <Typography fontWeight="bold" sx={{ mb: 0.8 }}>
                                Aadhaar*
                              </Typography>
                              <TextField
                                required
                                placeholder="Aadhaar*"
                                id="aadhaar_number"
                                type={'number'}
                                variant="outlined"
                                disabled={alert.loading || alert.show}
                                name={`applicants.${_index}.aadhaar_number`}
                                value={formik.values.applicants[_index].aadhaar_number}
                                onChange={(_value) => {
                                  formik.handleChange(_value);
                                }}
                                onInput={(e) => {
                                  e.target.value = Math.max(0, e.target.value).toString().slice(0, 12);
                                }}
                                error={IsErrorMethod('aadhaar_number', _index)}
                                helperText={
                                  IsErrorMethod('aadhaar_number', _index) &&
                                  formik.errors.applicants[_index].aadhaar_number
                                }
                                onBlur={formik.handleBlur}
                                fullWidth
                              />
                            </Grid>
                            <Grid item md={3} xs={12}>
                              <Typography fontWeight="bold" sx={{ mb: 0.8 }}>
                                PAN*
                              </Typography>
                              <TextField
                                required
                                placeholder="PAN*"
                                id="pan"
                                variant="outlined"
                                disabled={alert.loading || alert.show}
                                name={`applicants.${_index}.pan`}
                                value={formik.values.applicants[_index].pan}
                                onChange={(_value) => {
                                  formik.handleChange(_value);
                                }}
                                inputProps={{ maxLength: 10 }}
                                onInput={toInputUppercase}
                                error={IsErrorMethod('pan', _index)}
                                helperText={IsErrorMethod('pan', _index) && formik.errors.applicants[_index].pan}
                                onBlur={formik.handleBlur}
                                fullWidth
                              />
                            </Grid>
                          </Box>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          {' '}
                          <Box sx={{ pt: 2 }}>
                            <InputLabel>{`Upload Aadhaar (png/jpg/jpeg/pdf)*`}</InputLabel>
                            <Box
                              sx={{
                                p: 1,
                              }}
                            />
                            <div className="file-uploader">
                              <label htmlFor={`aadhar${_index}`}>
                                <input
                                  type="file"
                                  id={`aadhar${_index}`}
                                  required
                                  hidden
                                  name="aadhardoc"
                                  onChange={(event) => aadharChangedHandler(event, _index)}
                                />
                                <p type="button" className="button button--effect mr-2  bg-amber-300 rounded-md ">
                                  Choose File
                                  {formik.values.applicants[_index].aadharLoading && (
                                    <CircularProgress
                                      size={'1.5rem'}
                                      sx={{ width: 10, fontSize: '1rem', color: '#777' }}
                                    />
                                  )}
                                </p>
                                {!formik.values.applicants[_index].aadharLoading && (
                                  <>{formik.values.applicants[_index].aadharName}</>
                                )}
                              </label>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Box sx={{ pt: 2 }}>
                            <InputLabel>{`Upload PAN (png/jpg/jpeg/pdf)*`}</InputLabel>
                            <Box
                              sx={{
                                p: 1,
                              }}
                            />
                            <div className="file-uploader">
                              <label htmlFor={`pan${_index}`}>
                                <input
                                  type="file"
                                  id={`pan${_index}`}
                                  required
                                  hidden
                                  name="pandoc"
                                  onChange={(event) => panChangedHandler(event, _index)}
                                />
                                <p type="button" className="button button--effect mr-2  bg-amber-300 rounded-md ">
                                  Choose File
                                  {formik.values.applicants[_index].panLoading && (
                                    <CircularProgress
                                      size={'1.5rem'}
                                      sx={{ width: 10, fontSize: '1rem', color: '#777' }}
                                    />
                                  )}
                                </p>
                                {!formik.values.applicants[_index].panLoading && (
                                  <>{formik.values.applicants[_index].panName}</>
                                )}
                              </label>
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              ))}
            </form>

            <Button
              className="justify-center bg-White-700 mb-20 right-0 relative  float-right text-l font-bold "
              onClick={() => addField()}
              disabled={isApplicantDisabled}
            >
              Add New Applicant
            </Button>
            <Box
              sx={{
                p: 2,
              }}
            />
            <Divider />
          </Grid>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={() => saveAll()} disabled={isApplicantDisabled}>
              {'Next'}
            </Button>
          </Box>
        </>
      ) : activeStep === 3 ? (
        <>
          <Grid item lg={8} md={6} xs={12} pt={8}>
            <form autoComplete="off" noValidate>
              <Card>
                <CardHeader subheader="The information can be edited" title="Payment Information" />
                <Box
                  sx={{
                    p: 2,
                  }}
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          p: 2,
                        }}
                      >
                        <InputLabel className=" text-black">Please draw the Cheque/DD in favour of </InputLabel>
                        <InputLabel className="font-bold text-black">&nbsp;: ARACKAL DEVELOPERS LIMITED </InputLabel>
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Select payment method"
                        name="payment_type"
                        onChange={handlePaymentChange}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={valuesPayment.payment_type}
                        variant="outlined"
                      >
                        {payment.map((option) => (
                          <option key={option.payment_method_type_id} value={option.payment_method_type_id}>
                            {option.name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    {valuesPayment.payment_type === '2' ? (
                      <>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Bank name"
                            name="name"
                            onChange={handlePaymentChange}
                            required
                            value={valuesPayment.name}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Ref. Number"
                            name="ref"
                            onChange={handlePaymentChange}
                            required
                            value={valuesPayment.ref}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Amount"
                            name="amount"
                            onChange={handlePaymentChange}
                            required
                            disabled
                            value={values.totalAmount}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          {' '}
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                              <DesktopDatePicker
                                label="Date"
                                inputFormat="MM/DD/YYYY"
                                value={bankdate}
                                onChange={handlePaymentbankDateChange}
                                renderInput={(params) => <TextField {...params} />}
                              />{' '}
                            </Stack>
                          </LocalizationProvider>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          {' '}
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                              <DesktopDatePicker
                                label="Date of transfer"
                                inputFormat="MM/DD/YYYY"
                                value={bankdateOfTxn}
                                onChange={handlePaymentbankTxnDateChange}
                                renderInput={(params) => <TextField {...params} />}
                              />{' '}
                            </Stack>
                          </LocalizationProvider>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Amount"
                            name="amount"
                            onChange={handlePaymentChange}
                            required
                            disabled
                            value={values.totalAmount}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            label="Cheque/DD No."
                            name="cheque_number"
                            onChange={handlePaymentChange}
                            required
                            value={valuesPayment.cheque_number}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item md={6} xs={12}>
                          {' '}
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                              <DesktopDatePicker
                                label="Date"
                                inputFormat="MM/DD/YYYY"
                                value={dddate}
                                onChange={handlePaymentddDateChange}
                                renderInput={(params) => <TextField {...params} />}
                              />{' '}
                            </Stack>
                          </LocalizationProvider>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          {' '}
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                              <DesktopDatePicker
                                label="Date of transfer"
                                inputFormat="MM/DD/YYYY"
                                value={dddateOfTxn}
                                onChange={handlePaymentTxnDateChange}
                                renderInput={(params) => <TextField {...params} />}
                              />{' '}
                            </Stack>
                          </LocalizationProvider>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </CardContent>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2,
                  }}
                >
                  <InputLabel className="font-bold text-black">
                    Total Due on Application : {values.totalAmount}
                  </InputLabel>
                </Box>
              </Card>
            </form>
          </Grid>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handlePaymentNext} disabled={isPaymentDisabled}>
              {'Finish'}
            </Button>
          </Box>
        </>
      ) : activeStep === 2 ? (
        <>
          <Grid item lg={8} md={6} xs={12} pt={8}>
            <form onSubmit={formikNominees.handleApplicantNext}>
              <Box sx={{ display: 'flex' }}>
                <Typography sx={{ fontWeight: 'bold' }}>Nominee's Information</Typography>
              </Box>
              {formikNominees.values.nominees.map((_item, _index) => (
                <Box key={_index}>
                  <Box
                    sx={{
                      my: 2,
                      p: 2,
                      display: 'block',
                    }}
                  >
                    {_index !== 0 && <Divider sx={{ mb: 2 }} />}
                    <Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography fontWeight="bold" sx={{ mb: 0.8, display: 'flex' }}>
                          Nominee {_index + 1}
                          {_index !== 0 && (
                            <Typography
                              onClick={() => removeNominee(_index)}
                              fontWeight="bold"
                              sx={{
                                ml: 3,
                                mb: 0.8,
                                display: 'flex',
                                justifyContent: 'space-between',
                                color: '#2c6dd4',
                                cursor: 'pointer',
                              }}
                            >
                              - Remove Nominee
                            </Typography>
                          )}
                        </Typography>
                      </Box>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            required
                            name={`nominees.${_index}.name`}
                            id="name"
                            label="Nominee's name"
                            variant="outlined"
                            placeholder="Nominee's name"
                            disabled={alert.loading || alert.show}
                            value={formikNominees.values.nominees[_index].name}
                            onChange={(_value) => {
                              formikNominees.handleChange(_value);
                            }}
                            error={IsErrorNomineeMethod('name', _index)}
                            helperText={
                              IsErrorNomineeMethod('name', _index) && formikNominees.errors.nominees[_index].name
                            }
                            onBlur={formikNominees.handleBlur}
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            required
                            label="Name of Spouse/Parent"
                            placeholder="Name of Spouse/Parent"
                            id="relationship_name"
                            variant="outlined"
                            disabled={alert.loading || alert.show}
                            name={`nominees.${_index}.relationship_name`}
                            value={formikNominees.values.nominees[_index].relationship_name}
                            onChange={(_value) => {
                              formikNominees.handleChange(_value);
                            }}
                            error={IsErrorNomineeMethod('relationship_name', _index)}
                            helperText={
                              IsErrorNomineeMethod('relationship_name', _index) &&
                              formikNominees.errors.nominees[_index].relationship_name
                            }
                            onBlur={formikNominees.handleBlur}
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Box sx={{ pt: 2 }}>
                            <TextField
                              fullWidth
                              label="Relationship with the Applicant"
                              name={`nominees.${_index}.relationship_type_id`}
                              onChange={(_value) => {
                                formikNominees.handleChange(_value);
                              }}
                              required
                              select
                              SelectProps={{ native: true }}
                              value={formikNominees.values.nominees[_index].relationship_type_id}
                              variant="outlined"
                            >
                              {relation.map((option) => (
                                <option key={option.relationship_type_id} value={option.relationship_type_id}>
                                  {option.name}
                                </option>
                              ))}
                            </TextField>
                          </Box>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <Box sx={{ pt: 2 }}>
                            <FormControl>
                              <FormLabel id="demo-row-radio-buttons-group-label" required>
                                Sex
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name={`nominees.${_index}.gender_type_id`}
                                value={formikNominees.values.nominees[_index].gender_type_id}
                                onChange={(_value) => {
                                  formikNominees.handleChange(_value);
                                }}
                              >
                                <FormControlLabel value="2" control={<Radio />} label="Female" />
                                <FormControlLabel value="1" control={<Radio />} label="Male" />
                              </RadioGroup>
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <Typography fontWeight="bold" sx={{ mb: 0.8 }}>
                            DOB
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              name={`nominees.${_index}.date_of_birth`}
                              id="joining_date_n"
                              views={['year', 'month', 'day']}
                              disabled={alert.loading || alert.show}
                              value={formikNominees.values.nominees[_index].date_of_birth}
                              onChange={(value) => {
                                const dt = moment(new Date(value)).format('yyyy/MM/DD');
                                formikNominees.setFieldValue(
                                  `nominees.${_index}.date_of_birth`,
                                  dt.replaceAll('/', '-')
                                );
                              }}
                              onBlur={formikNominees.handleBlur}
                              renderInput={(params) => <TextField fullWidth {...params} error={false} />}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Typography fontWeight="bold" sx={{ mb: 0.8 }}>
                            Nominee Percentage*
                          </Typography>
                          <TextField
                            required
                            placeholder="Nominee Percentage"
                            id="nominee_percentage"
                            type={'number'}
                            variant="outlined"
                            disabled={alert.loading || alert.show}
                            name={`nominees.${_index}.nominee_percentage`}
                            value={formikNominees.values.nominees[_index].nominee_percentage}
                            onChange={(_value) => {
                              formikNominees.handleChange(_value);
                            }}
                            error={IsErrorNomineeMethod('nominee_percentage', _index)}
                            helperText={
                              IsErrorNomineeMethod('nominee_percentage', _index) &&
                              formikNominees.errors.nominees[_index].nominee_percentage
                            }
                            onBlur={formikNominees.handleBlur}
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Typography fontWeight="bold" sx={{ mb: 0.8 }}>
                            Aadhaar*
                          </Typography>
                          <TextField
                            required
                            placeholder="Aadhaar"
                            id="aadhaar_number"
                            type={'number'}
                            variant="outlined"
                            disabled={alert.loading || alert.show}
                            name={`nominees.${_index}.aadhaar_number`}
                            value={formikNominees.values.nominees[_index].aadhaar_number}
                            onChange={(_value) => {
                              formikNominees.handleChange(_value);
                            }}
                            onInput={(e) => {
                              e.target.value = Math.max(0, e.target.value).toString().slice(0, 12);
                            }}
                            error={IsErrorNomineeMethod('aadhaar_number', _index)}
                            helperText={
                              IsErrorNomineeMethod('aadhaar_number', _index) &&
                              formikNominees.errors.nominees[_index].aadhaar_number
                            }
                            onBlur={formikNominees.handleBlur}
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Typography fontWeight="bold" sx={{ mb: 0.8 }}>
                            PAN
                          </Typography>
                          <TextField
                            placeholder="PAN"
                            id="pan"
                            variant="outlined"
                            disabled={alert.loading || alert.show}
                            name={`nominees.${_index}.pan`}
                            value={formikNominees.values.nominees[_index].pan}
                            onChange={(_value) => {
                              formikNominees.handleChange(_value);
                            }}
                            inputProps={{ maxLength: 10 }}
                            onInput={toInputUppercase}
                            error={IsErrorNomineeMethod('pan', _index)}
                            helperText={
                              IsErrorNomineeMethod('pan', _index) && formikNominees.errors.nominees[_index].pan
                            }
                            onBlur={formikNominees.handleBlur}
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          {' '}
                          <Box sx={{ pt: 2 }}>
                            <InputLabel>{`Upload Aadhaar (png/jpg/jpeg/pdf)*`}</InputLabel>
                            <Box
                              sx={{
                                p: 1,
                              }}
                            />
                            <div className="file-uploader">
                              <label htmlFor={`NomineeAadhar${_index}`}>
                                <input
                                  type="file"
                                  id={`NomineeAadhar${_index}`}
                                  required
                                  hidden
                                  name="pandoc"
                                  onChange={(event) => aadharNomineeChangedHandler(event, _index)}
                                />
                                <p
                                  type="button"
                                  disabled
                                  className="button button--effect mr-2  bg-amber-300 rounded-md "
                                >
                                  Choose File{' '}
                                  {formikNominees.values.nominees[_index].aadharLoading && (
                                    <CircularProgress
                                      size={'1.5rem'}
                                      sx={{ width: 10, fontSize: '1rem', color: '#777' }}
                                    />
                                  )}
                                </p>
                                {!formikNominees.values.nominees[_index].aadharLoading && (
                                  <>{formikNominees.values.nominees[_index].aadharName}</>
                                )}
                              </label>
                            </div>
                          </Box>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Box sx={{ pt: 2 }}>
                            <InputLabel>{`Upload PAN (png/jpg/jpeg/pdf)`}</InputLabel>
                            <Box
                              sx={{
                                p: 1,
                              }}
                            />
                            <div className="file-uploader">
                              <label htmlFor={`NomineePan${_index}`}>
                                <input
                                  type="file"
                                  id={`NomineePan${_index}`}
                                  required
                                  hidden
                                  name="pandoc"
                                  onChange={(event) => panNomineeChangedHandler(event, _index)}
                                />
                                <p type="button" className="button button--effect mr-2  bg-amber-300 rounded-md ">
                                  Choose File
                                  {formikNominees.values.nominees[_index].panLoading && (
                                    <CircularProgress
                                      size={'1.5rem'}
                                      sx={{ width: 10, fontSize: '1rem', color: '#777' }}
                                    />
                                  )}
                                </p>
                                {!formikNominees.values.nominees[_index].panLoading && (
                                  <>{formikNominees.values.nominees[_index].panName}</>
                                )}
                              </label>
                              {/* <input
                                type="file"
                                required
                                name="pandoc"
                                onChange={(event) => panNomineeChangedHandler(event, _index)}
                              /> */}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              ))}
            </form>
            <Button
              className="justify-center bg-White-700 mb-20 right-0 relative  float-right text-l font-bold  "
              disabled={isNomineeDisabled}
              onClick={() => addNewField()}
            >
              Add New Nominee
            </Button>
            <Box
              sx={{
                p: 2,
              }}
            />
            <Divider />
          </Grid>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button disabled={isNomineeDisabled} onClick={() => saveAllNominee()}>
              {'Next'}
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            <Button onClick={handleNext}>{'Next'}</Button>
          </Box>
        </>
      )}
    </Box>
  );
}
