/* eslint-disable camelcase */
import { Auth } from 'aws-amplify';
import React from 'react';
import { useNavigate } from 'react-router';
import { Alert, Snackbar, TextField } from '@mui/material';

export default function SignUp() {
  const navigate = useNavigate();

  const [given_name, setgiven_name] = React.useState('');
  const [family_name, setfamily_name] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setpassword] = React.useState('');
  const [cpassword, setCpassword] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  const handleSignUp = async () => {
    localStorage.setItem('username', email);

    const username = email;
    const props = {
      username,
      password,
      attributes: {
        email, // optional
        given_name,
        family_name,
      },
      autoSignIn: {
        // optional - enables auto sign in after user is confirmed
        enabled: true,
      },
    };
    await signUp(props);
  };
  async function signUp(props) {
    try {
      await Auth.signUp(props);
      navigate('/confirm-sign-up');
    } catch (error) {
      console.log('error signing up:', error);
      setErrorMessage(error.toString());
    }
  }
  const [open, setOpen] = React.useState(true);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:max-w-xl">
        <h1 className="text-3xl font-bold text-center text-stone-900 uppercase">Sign Up</h1>
        <form className="mt-6">
          <div className="mb-2">
            <TextField
              fullWidth
              label="First Name"
              name="given_name"
              onChange={(e) => setgiven_name(e.target.value)}
              required
              value={given_name}
              variant="outlined"
            />
          </div>
          <div className="mb-2">
            <TextField
              fullWidth
              label="Last Name"
              name="family_name"
              onChange={(e) => setfamily_name(e.target.value)}
              required
              value={family_name}
              variant="outlined"
            />
          </div>
          <div className="mb-2">
            <TextField
              fullWidth
              label="Email Address"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              required
              value={email}
              variant="outlined"
            />
          </div>
          <div className="mb-2">
            <TextField
              fullWidth
              label="Password"
              margin="normal"
              name="password"
              onChange={(e) => setpassword(e.target.value)}
              type="password"
              value={password}
              required
              variant="outlined"
            />
          </div>
          <div className="mb-2">
            <TextField
              fullWidth
              label="Confirm Password"
              margin="normal"
              name="password"
              onChange={(e) => setCpassword(e.target.value)}
              type="password"
              value={cpassword}
              required
              variant="outlined"
            />
          </div>
          <div className="mt-6">
            <button
              type="button"
              className="button button--effect w-full px-4 py-2 bg-amber-300 rounded-md"
              onClick={handleSignUp}
            >
              Sign Up
            </button>
          </div>
        </form>
        <p className="mt-8 text-xs font-light text-center text-gray-700">
          {' '}
          Already have an account?{' '}
          <a href="/login" className="font-medium text-lime-600 hover:underline">
            Login
          </a>
        </p>
        {errorMessage !== '' && (
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            bodyStyle={{ backgroundColor: 'teal', color: 'coral' }}
          >
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
              {errorMessage}
            </Alert>
          </Snackbar>
        )}
      </div>
    </div>
  );
}
