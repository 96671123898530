import { Box, CircularProgress, Container, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProperties } from '../../store/products.slice';
import { ProductCard } from '../product/product-card';
import PropertyCard from './propertyCard';

const product = {
  product_id: 9,
  name: '15% interest return ',
  brief: 'Most return inetest',
  share_price: 401,
  description: '<p>Most return inetest</p>',
  is_active: true,
  slug: '15%-interest-return',
  image_url:
    'https://havendo-67-000001-04-dev.s3.amazonaws.com/product/images/692b0907-f93f-46fe-8f9e-87f17704680a.png',
  rate_of_dividend: 15,
  maturity_period: 12,
  created_at: '2023-04-06T18:45:25',
  updated_at: '2023-04-06T18:45:25',
  created_by: 'Anuroop Vijayan',
  updated_by: 'Anuroop Vijayan',
};

const Properties = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProperties());
  }, []);

  const propertiesState = useSelector((state) => state.products);

  const [properties, setProperties] = useState([]);

  useEffect(() => setProperties(propertiesState.propertyList?.items || []), [propertiesState]);


  // if (propertiesState.loading) {
  //   return <CircularProgress />;
  // }

  return (
    <Container>
      <Typography sx={{ fontWeight: 'bold', fontSize: '1.4rem', mb: 3 }}>Properties</Typography>

      <Box sx={{ pt: 2 }}>
        <Grid container spacing={3}>
          {propertiesState.loading && (
            <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%', pt: 10 }}>
              <CircularProgress />
            </Box>
          )}
          {properties.map((val) => (
            <Grid item key={val.property_id} lg={4} md={6} xs={12}>
              <PropertyCard product={val} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Properties;
