import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Alert, CircularProgress, Snackbar, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { signIn } from '../store/sign-in.slice';
import { getProfile } from '../store/profile.slice';

export default function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Handles Password flow
  async function signInFunc() {
    setIsLoading(true);
    try {
      const user = await Auth.signIn(username, password);
      const payload = {
        userAttributes: user.attributes,
        isLoggedIn: true,
      };
      dispatch(signIn(payload));
      await dispatch(getProfile(user.attributes.email));
      setIsLoading(false);
      if (user) navigate('/dashboard/app');
    } catch (error) {
      console.log('error signing in', error);
      setErrorMessage(error.toString());
      setIsLoading(false);
    }
  }

  // Handles federated sign-in using Google
  async function handleGoogleLogin() {
    await Auth.federatedSignIn({ provider: 'Google' });
  }

  const [open, setOpen] = React.useState(true);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:max-w-xl">
        <h1 className="text-3xl font-bold text-center text-stone-900 uppercase">Log in</h1>
        <form className="mt-6">
          <div className="mb-2">
            <TextField
              fullWidth
              label="Email Address"
              name="email"
              onChange={(e) => setUsername(e.target.value)}
              required
              value={username}
              variant="outlined"
            />
          </div>
          <div className="mb-2">
            <TextField
              fullWidth
              label="Password"
              margin="normal"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              value={password}
              variant="outlined"
            />
          </div>
          {/* <a href="#" className="text-xs text-lime-600 hover:underline">
            Forgot Password?
          </a> */}
          <div className="mt-6">
            <button
              type="button"
              className="button button--effect w-full px-4 py-2 bg-amber-300 rounded-md "
              onClick={signInFunc}
              disabled={isLoading}
            >
              Login {isLoading && <CircularProgress size={'1.5rem'} sx={{ color: '#777' }} />}
            </button>
          </div>
        </form>
        <div className="relative flex items-center justify-center w-full mt-6  ">
          <div className="absolute px-5">Or</div>
        </div>
        <div className="flex mt-8 gap-x-2">
          <button
            type="button"
            className="flex items-center justify-center w-full p-2 border border-gray-600 rounded-md focus:ring-2 focus:ring-offset-1"
            onClick={handleGoogleLogin}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className="w-5 h-5 fill-current">
              <path d="M16.318 13.714v5.484h9.078c-0.37 2.354-2.745 6.901-9.078 6.901-5.458 0-9.917-4.521-9.917-10.099s4.458-10.099 9.917-10.099c3.109 0 5.193 1.318 6.38 2.464l4.339-4.182c-2.786-2.599-6.396-4.182-10.719-4.182-8.844 0-16 7.151-16 16s7.156 16 16 16c9.234 0 15.365-6.49 15.365-15.635 0-1.052-0.115-1.854-0.255-2.651z" />
            </svg>
            <p className="pl-4">Sign in with Google</p>
          </button>
        </div>

        <p className="mt-8 text-xs font-light text-center text-gray-700">
          {' '}
          Don't have an account?{' '}
          <a href="/sign-up" className="font-medium text-lime-600 hover:underline">
            Sign up
          </a>
        </p>
        {errorMessage !== '' && (
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            bodyStyle={{ backgroundColor: 'teal', color: 'coral' }}
          >
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
              {errorMessage}
            </Alert>
          </Snackbar>
        )}
      </div>
    </div>
  );
}
