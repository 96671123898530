/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../../store/profile.slice';
import { profileFileUpload } from '../../store/fileupload.slice';

export const AccountProfile = (props) => {
  const dispatch = useDispatch();

  /* Get the State of the Product slice */
  const profileState = useSelector((state) => state.profile);
  const { profile } = profileState;
  const [loading, setLoading] = useState(false);

  // function handle when we change file
  const fileChangedHandler = async (event) => {
    setLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.append('file', event.target.files[0]);

    // data for file upload API
    const payload = {
      userId: profile?.user_id,
      file_content: bodyFormData,
    };
    await dispatch(profileFileUpload(payload));
    await dispatch(getProfile(props.email));
    setLoading(false);
  };

  return (
    <Card {...props}>
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Avatar
            src={profile?.image}
            sx={{
              height: 64,
              mb: 2,
              width: 64,
            }}
          />
          <Typography color="textPrimary" gutterBottom variant="h5">
            {profile.first_name} {profile.last_name}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions className="justify-center flex">
        <Button disabled={loading} variant="text" component="label">
          {loading ? <CircularProgress /> : 'Upload picture'}
          <input hidden accept="image/*" multiple type="file" onChange={fileChangedHandler} />
        </Button>
      </CardActions>
    </Card>
  );
};
