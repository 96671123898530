import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import store from '../redux/store';

const initialStateObject = {
  loading: false,
  fileUploadResponse: {
    loading: null,
    error: '',
    data: {
      employee_id: '',
      employee_document_id: '',
    },
  },
  error: '',
};

const fileUploadApiUrl = `${process.env.REACT_APP_API_BASE_URL}user-management/users/`;

export const profileFileUpload = createAsyncThunk('fileupload/profileFileUpload', (payload) => {
  return axios
    .post(`${fileUploadApiUrl + payload?.userId}/upload-profile`, payload?.file_content)
    .then((response) => response.data);
});

const fileuploadSlice = createSlice({
  name: 'fileupload',
  /* file Upload */
  initialState: initialStateObject,
  extraReducers: (builder) => {
    builder.addCase(profileFileUpload.pending, (state) => {
      state.fileUploadResponse.loading = true;
    });
    builder.addCase(profileFileUpload.fulfilled, (state, action) => {
      state.fileUploadResponse.loading = false;
      state.fileUploadResponse = action.payload;
      state.fileUploadResponse.error = '';
    });
    builder.addCase(profileFileUpload.rejected, (state, action) => {
      state.fileUploadResponse.loading = false;
      state.fileUploadResponse.error = action.error.message;
    });
  },
});

export default fileuploadSlice.reducer;
